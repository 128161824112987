import { FC, ReactNode, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHandleField } from "../../hooks/useHandleField";
import useTicket, { IMsgForm } from "../../hooks/useTicket";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IOrderItem } from "../../model/IOrder";
import { ITicket } from "../../model/ITicket";
import { msg } from "../../msg";
import { getDictionary } from "../../store/ticketCommonSlice";
import { getTickets } from "../../store/ticketSlice";
import Loading from "../common/Loading";
import RadioList from "../form/RadioList";
import TicketMessage from "../tickets/TicketMessage";
import TicketMessages from "../tickets/TicketMessages";
import TimeLineVertical from "../TimeLineVertical";
import SubTitle from "../title/SubTitle";
import Title from "../title/Title";
import OrderItem from "./OrderItem";
import Button from "../form/Button";
import { getOrder } from "../../store/orderSlice";
import { Link } from "react-router-dom";

export interface IReturnForm {
  additionalDetails: string,
  attachments?: File[],
  reason: string,
  orderItemId: number,
}

const OrderReturnItem: FC<IOrderItem> = ({ ...activeOrderItem }) => {
  const tickets = useTypedSelector(getTickets());
  const dictionary = useTypedSelector(getDictionary());
  const { addReturn, addMsg } = useTicket();
  const [loading, setLoading] = useState<boolean>(false);   
 
  const order = useTypedSelector(getOrder());  

  // --
  // -- Add Ticket flow
  // --
  const initReturnForm: IReturnForm = {
    additionalDetails: '',
    attachments: [],
    reason: '',
    orderItemId: activeOrderItem.id,
  };

  const [state, setState] = useState<IReturnForm>(initReturnForm);
  const [stateErr, setStateErr] = useState<Partial<Record<keyof IReturnForm, string>>>(); 
  const handleField = useHandleField(setState);

  const handleAddTicket = async () => {
    setLoading(() => true);
    setStateErr(() => undefined);
    const errs = await addReturn(state);
    if (errs)
      setStateErr(() => errs);
    else {
      toast.success(msg('return request was sended successfully'));      
      setState(() => initReturnForm);
    }
    setLoading(() => false);
  };   

  // --
  // -- Edit the existed ticket flow
  // --
  const [currentTicket, setcurrentTicket] = useState<ITicket | null>();
  const { searchTicketByTypeIds } = useTicket(); 
  useEffect(() => {    
    setcurrentTicket(() => searchTicketByTypeIds({
      typeIds: [2, 6, 7, 8, 9],
      searchOrderItemId: activeOrderItem?.id,
      ticketArr: tickets,
    }));
  
    setState((prevState) => ({
      ...prevState,
      orderItemId: activeOrderItem.id,
    }));
  }, [activeOrderItem?.id, tickets]);  

  const initReturnMsgForm: IMsgForm = {
    message: '',
    ticketId: currentTicket?.id ? String(currentTicket.id) : '',
  };

  const [stateMsg, setStateMsg] = useState<IMsgForm>(initReturnMsgForm);
  const [stateMsgErr, setStateMsgErr] = useState<Partial<Record<keyof IMsgForm, string>>>();
  const handleMsgField = useHandleField(setStateMsg);

  useEffect(() => {
    setStateMsg((prevState) => ({
      ...prevState,
      ticketId: currentTicket?.id ? String(currentTicket.id) : '',
    }));
  }, [currentTicket?.id]);
  
  const handleAddMsg = async () => {
    setLoading(() => true);
    setStateMsgErr(() => undefined);
    const errs = await addMsg(stateMsg);   
    if (errs)
      setStateMsgErr(() => errs);
    else {
      toast.success(msg('message was sended successfully'));      
      setStateMsg(() => initReturnMsgForm);
    }
    setLoading(() => false);
  };

  console.log('state = ', state);
  

  const getReturnPageLink = (): ReactNode => order?.fromSite
    ? (
      <>
        Visit out&nbsp;
        <Link to={{
              'allusedparts.com': 'https://allusedparts.com/returns.html',
              'shop.buyusedengine.com': 'https://shop.buyusedengine.com/returns.html',
              'automotix.net': 'https://automotix.net/return-policy.html',
            }[order.fromSite] || 'https://automotix.net/return-policy.html'
          }
          target="_blank"
          className="text-red-600"        
        >
          Return Page
        </Link>
        &nbsp;for more information.
      </>
    ) : <></>;
    
  return currentTicket === undefined
    ? <Loading />
    : (
      <div className={`flex flex-col gap-4 ${!!loading && 'animate-pulse'}`}>
        <Title>Return Item</Title>
        <OrderItem {...activeOrderItem} size="medium" />       

        {!!currentTicket && (
          <>
            <SubTitle>Return Status</SubTitle>
            <TimeLineVertical ticket={currentTicket} />
          </>
        )}
        
        {currentTicket === null
          ? (
            <>
              <SubTitle>  Select the reason why you want to return these items (required)</SubTitle>
              {!!dictionary?.returns?.length && (
                <RadioList
                  name="reason"
                  value={state.reason}
                  handle={handleField('reason')}
                  options={dictionary.returns.map(({ id, type, text, description }) => ({
                    title: text,
                    value: id,
                    description: <>{description} {id === 4 && getReturnPageLink()}</>, // Dmitriy forced me do it
                  }))}                    
                  error={stateErr?.reason || ''}
                />
              )}
              {state.reason !== '4'
                ? (
                  <>
                    <SubTitle>Please describe the issue in detail and upload any supporting photos (required)</SubTitle>
                    <div>Upload photos of the part, tag attached to the part, and vehicle. Once we process your request, we will follow up with return instructions for the original part and then process a replacement. If you do not want a replacement, please let us know in the comments section below.</div>   
                    <TicketMessage
                      label="Additional Details"
                      name="details"
                      placeholder="Please describe in detail"
                      value={state.additionalDetails}
                      handle={handleField('additionalDetails')}
                      error={stateErr?.additionalDetails || ''}
                      disabled={loading}
                      setFileState={(attachments: File[]) => setState((prevState) => ({
                        ...prevState,
                        attachments,
                      }))}
                      files={state.attachments}
                      handleSend={handleAddTicket}
                    />
                  </>
                ) : (
                  <div className="flex">
                    <Button
                      variant="outline"
                      
                      isNotFullWidth
                      onClick={handleAddTicket}
                    >
                      Continue
                    </Button>
                  </div>
                )}              

            </>
          ) : (
            <>
              {currentTicket?.messages?.length && (
                <div className="mt-6">
                  <div className="mb-4 font-semibold">Messages</div>
                  <TicketMessages ticket={currentTicket} />
                </div>
              )}
              <TicketMessage
                label={ !currentTicket?.messages?.length ? 'Additional Details' : ''}
                name="details"
                placeholder={ !currentTicket?.messages?.length ? 'Please describe in additional detail' : 'Message Customer Support...'}
                value={stateMsg.message}
                handle={handleMsgField('message')}
                error={stateMsgErr?.message || ''}
                disabled={loading}
                setFileState={(attachments: File[]) => setStateMsg((prevState) => ({
                  ...prevState,
                  attachments,
                }))}
                files={stateMsg.attachments}
                handleSend={handleAddMsg}
                butTitle={ !currentTicket?.messages?.length ? 'Continue' : 'Send Message'}
              />
            </>
          )        
        }
      </div>
    );
};

export default OrderReturnItem;
