import { FC, ReactNode } from "react";
import { IInput, IInputOption, TInputEvent } from "./Input";
import { TFormSize } from "./TFormSize";

const RadioList: FC<IInput> = ({ 
  options,
  name,
  value,
  handle, 
  size = 'medium', 
  error,
}) => {
  const scale: Record<TFormSize, { width: number, gap: number }> = {
    'tiny':   { width: 16, gap: 8 },
    'small':  { width: 18, gap: 10 },
    'medium': { width: 20, gap: 10 },
    'large':  { width: 24, gap: 14 },
  }

  return (
    <fieldset className="flex flex-col" style={{
      gap: scale[size].gap,
    }}>
      {!!options?.length && options.map(({ title, value: valueItem, description }) => (
        <div key={`radio-${valueItem}`} className="flex flex-col gap-2 justify-center items-start">
          <div className="flex items-center">
            <input      
              style={{
                width: scale[size].width,
                height: scale[size].width,
              }}        
              id={`radio-${valueItem}`}
              name={name}
              type="radio"
              value={String(valueItem)}
              checked={String(valueItem) === String(value)}
              className="border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onChange={handle}
            />
            <label
              htmlFor={`radio-${valueItem}`}
              className={`
                ml-3 block font-medium leading-6 text-gray-900
                ${error ? 'text-pink-600' : ''}
              `}
            >
              {title}
            </label>
          </div>
          {String(valueItem) === String(value) && description && (
            <div className="[&_a]:text-red">
              {description}
            </div>
          )}         
        </div>        
      ))} 
      {!!error && <div className="text-pink-600 text-sm mt-1">{error}</div>}    
    </fieldset>
  );
};

export default RadioList;
